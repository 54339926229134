import React from 'react'
import Background from '../../video/background1.mp4'
import logo from '../../img/topone-transparency.png';

export default function Welcome() {
    return (
        <div id='welcome'>
            <div className='video-container'>
                <img className='logo' alt='logo' src={logo}></img>
                <video autoPlay loop muted >
                    <source src={Background} type='video/mp4' />
                    <source src={Background} type="video/ogg" />
                </video>
            </div>
        </div>
    )
}