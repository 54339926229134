import Select from "react-select";
import countryList from "react-select-country-list";
import React, { useState, useMemo } from "react";
import Recaptcha from "../elements/Recaptcha";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import InnerPop from "../elements/InnerPop";

import Axios from "axios";

export default function Contact(props) {
  init("user_PXR23NFHesbflxVcO00KJ");
  const [countryValue, setValue] = useState("");
  const [natureValue, setValueEnq] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNr, setPhoneNr] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subscription, setSub] = useState(false);
  const [message, setMessage] = useState("");
  const [btnTxt, setBtnTxt] = useState("Submit");
  const [loading, setLoading] = useState(false);
  const [innerPopUp, setInnerPopUp] = useState(false);
  const [innerMsg, setInnerMsg] = useState("");
  const [innerState, setinnerState] = useState(false);

  const options = useMemo(() => countryList().getData(), []);

  const optionsEnq = [
    { value: "demo", label: "Request a demo" },
    { value: "question", label: "Ask us a question" },
    { value: "demo", label: "Invite us to an event" },
    { value: "demo", label: "Discuss a partnership" },
    { value: "demo", label: "Speak to a sales representative" },
  ];
  const getData = () => {
    const formData = {
      firstName,
      lastName,
      phoneNr,
      email,
      subscription,
      country: countryValue,
      nature: natureValue,
      message,
    };
    return formData;
  };
  const saveDataToDB = (data) => {
    Axios.post(`${props.PORT}/contactUsCRM`, { ...data })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => console.log(err));
  };
  const cleanForm = () => {
    setValue("");
    setValueEnq("");
    setFirstName("");
    setPhoneNr("");
    setPhoneNr("");
    setLastName("");
    setEmail("");
    setSub(false);
    setMessage("");
  };
  const checkRequired = () => {
    if (firstName === "") return false;
    if (lastName === "") return false;
    if (email === "") return false;
    if (countryValue === "") return false;
    if (natureValue === "") return false;
    else return true;
  };

  const sendForm = (e) => {
    e.preventDefault();
    if (!checkRequired() === true) {
      setInnerPopUp(true);
      setinnerState(false);
      setInnerMsg("Required field is empty");
      return;
    }
    const data = getData();
    setBtnTxt("Loading...");
    setLoading(true);
    emailjs
      .send(
        "service_rtzy0pj",
        "template_i9mqill",
        data,
        "user_PXR23NFHesbflxVcO00KJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          saveDataToDB(data);
          cleanForm();
          setInnerPopUp(true);
          setinnerState(true);
          setInnerMsg("Contact Form Submitted! We will be in touch soon.");
          setBtnTxt("Submit");
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
          setInnerPopUp(true);
          setinnerState(false);
          setInnerMsg("Something went wrong");
          setBtnTxt("Submit");
          setLoading(false);
        }
      );
  };
  return (
    <section
      id="contact"
      className={`${props.show ? "visible" : "hide"} ${
        innerPopUp ? "msg" : ""
      }`}
    >
      <h2 className="section-title">Contact TopOne Team</h2>
      <div className="inner-container">
        <InnerPop msg={innerMsg} valid={innerState} />
        <form onSubmit={sendForm}>
          <div className="row">
            <label>First Name: *</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              name="firstName"
              type="text"
              placeholder="First Name"
              style={{ marginRight: "10px" }}
            />
            <label>Phone Number:</label>
            <input
              value={phoneNr}
              onChange={(e) => setPhoneNr(e.target.value)}
              name="phoneNumber"
              type="number"
              placeholder="Phone number"
              style={{ marginRight: "10px" }}
            />
          </div>

          <div className="row">
            <label>Last Name: *</label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              name="lastname"
              type="text"
              placeholder="Last Name"
            />
            <label>Work Email: *</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              type="text"
              placeholder="Work email address"
            />
          </div>

          <div className="row full">
            <label>Nature of your enquiry: *</label>
            <Select
              name="enquiry"
              className="select"
              options={optionsEnq}
              value={natureValue}
              onChange={setValueEnq}
            />
            <label>Select Countries: *</label>
            <Select
              name="country"
              className="select"
              options={options}
              value={countryValue}
              onChange={setValue}
            />
          </div>

          <div className="row text">
            <label>Message:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              name="message"
            ></textarea>
          </div>

          <div className="row-end">
            <input
              name="subscribe"
              type="checkbox"
              value={subscription}
              onChange={() => setSub(!subscription)}
            />
            <label>
              I would like to receive informative content from TopOne -- don't
              worry we will never fill up your inbox
            </label>
            <label>
              By clicking send bellow, you have read and agreed to TopOne's{" "}
              <button className="privacy" onClick={props.preferences}>
                TopOne Privacy Policy
              </button>
            </label>
          </div>

          <div className="row text" style={{ margin: "auto" }}>
            <Recaptcha />
          </div>
          <div className={`submit ${loading ? "loading" : ""}`}>
            <input type="submit" value={btnTxt} />
          </div>
        </form>
      </div>
    </section>
  );
}
