import React from "react";
import Data from "../../data/Database";
import Button from "./Button";

export default function LearnMore(props) {
  
  const serviceList = Data.learnMore.map((item) => {
    return (
      <div key={item.id} className="item">
        <p>
          {item.text}
        </p>
      </div>
    );
  });

  return (
    <div id="learn-more">
      <h2>TopOne Learn More</h2>
      <ul className="list">
        {/* {Data.learnMore.map((learn) => (
          <li key={learn.id} onClick={() => setPage(learn.id - 1)}>
            {learn.title}
          </li>
        ))} */}
      </ul>
      <div className="policy">
        <p>{serviceList[0]}</p>
        {/* {Data.learnMore[page].text.map((txt) => (
          <p className='item' >{serviceList }</p>
        ))}    */}
      </div>
      <Button click={props.closeLearnMore} text="close" />
    </div>
  );
}
