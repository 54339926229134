import React from "react";

export default function Menu(props) {
  return (
    <>
      <li>
        <a href="#welcome" style={{ textDecoration: "none", color: "black" }}>
          Home
        </a>
      </li>
      <li>
        <a href="#project" style={{ textDecoration: "none", color: "black" }}>
          Projects
        </a>
      </li>
      <li>
        <a href="#products" style={{ textDecoration: "none", color: "black" }}>
          Products
        </a>
      </li>
      <li>
        <a href="#services" style={{ textDecoration: "none", color: "black" }}>
          Services
        </a>
      </li>
      <li>
        <a href="#trainings" style={{ textDecoration: "none", color: "black" }}>
          Trainings
        </a>
      </li>
      <li>
        <a href="#free-book" style={{ textDecoration: "none", color: "black" }}>
          Free Book
        </a>
      </li>
      <li>
        <a
          href="https://www.toponecrm.com/blog/"
          style={{ textDecoration: "none", color: "black" }}
        >
          Blog
        </a>
      </li>
      <li>
        <a href="#contact" style={{ textDecoration: "none", color: "black" }}>
          Contact
        </a>
      </li>
    </>
  );
}
