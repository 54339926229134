import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import crmVideo from "../../video/V5_TopOneCRM.mp4";
import check from "../../img/icons/check.png";

export default function Introduction() {
  return (
    <div>
      <Container>
        <h2 className="section-title" style={{ marginTop: "50px" }}>
          Introduction
        </h2>
        <Row>
          <Col>
            <p>
              <img src={check} className="rotate" alt="tick"></img> TopOneCRM is
              a Information Technology (IT) Company which is delivering entire
              bespoke CRM and Cloud solutions using modern technologies.
              <br></br>
              <br></br>
              <img src={check} className="rotate" alt="tick"></img> If you are
              looking for a simple way to manage your business, our product
              TopOne CRM is a cost-effective and affordable CRM solution!
              <br></br>
              <br></br>
              <img src={check} className="rotate" alt="tick"></img> Our approach
              is to keep it simple and deliver quality solution by helping you
              offer your customers what they really need, not what you think
              they need.
            </p>
          </Col>
          <Col>
            <video autoPlay loop muted className="introVideo">
              <source src={crmVideo} type="video/mp4" />
            </video>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
