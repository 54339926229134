import {React, useContext} from "react";
import { Accordion, Card, AccordionContext } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";


import Data from "../../data/Database"

import "./../../css/accordion.css"
import "bootstrap/dist/css/bootstrap.min.css";

import add from "../../img/icons/add.png";
import minus from "../../img/icons/remove.png";


const Accordion1 = () => {
  
    function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Accordion
        type="button"
        style={{
          //backgroundColor: isCurrentEventKey ? "#1E90FF" : "rgba(0,0,0,.001)",
        }}
        onClick={decoratedOnClick}
      >
        {children}
        <img
          className="accordionIcon"
          src={isCurrentEventKey ? minus : add}
          alt="accordion icons"
        ></img>
      </Accordion>
    );
      }

  return (
    <div className="accordion">
      <h2 className="section-title">Questions & Answers.</h2>
      {Data.accordionInfo.map((item) => {
        return (
          <Accordion
            defaultActiveKey="1"
          >
            <Card
            className="Accordion-card"
              style={{
                backgroundColor: "#3BB9FF",
                borderColor: "#FFF",
              }}
            >
              <Card.Header className="Accordion-card-header">
                <ContextAwareToggle eventKey="0">
                  {item.title}
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body
                  style={{
                    backgroundColor: "#FFF",
                    borderColor: "#FFF",
                    borderBlockColor: "#FFF",
                    whiteSpace:"pre-wrap"
                  }}
                >
                  {item.text}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        );
      })}
    </div>
  );
};

 export default Accordion1


