import React from "react";
import Button from "./Button";

export default function FreeCall(props) {
  return (
    <div id="licensing" className={`pop-upL ${props.visible ? "visible" : ""}`}>
      <div className="card-content" style={{ height: "140px" }}>
        <div className="card-title">
          <h2>30 Minute Microsoft Licensing consultation</h2>
        </div>

        <div className="card-body">
          <pre style={{ overflow: "hidden", fontFamily: "Raleway " }}>
            Free unbiased, no-obligation consultation
          </pre>
          <pre style={{ overflow: "hidden", fontFamily: "Raleway" }}>
            Please drop an email to Contact@TopOneCRM.com{" "}
          </pre>
          <pre style={{fontFamily:"Raleway"}}>or call us on 0333 339 2226</pre>
        </div>
      </div>

      <div className="btn">
        <button>
          <a href="#contact">
            <Button text="Book now" click={props.closeLicensing} />
          </a>
        </button>
      </div>
      <span className="close" onClick={props.closeLicensing}>
        &#x2716;
      </span>
    </div>
  );
}
