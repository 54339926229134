import React from 'react'

export default function Recaptcha() {
    return (
      <div>
        <div
          className="g-recaptcha"
          data-sitekey="6LcPotoZAAAAAFGGiwh4TkKBWyhTuRj68g-fw2jq"
        ></div>
      </div>
    );
}
