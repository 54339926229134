import { React, useState } from "react";
import { Form, Col } from "react-bootstrap";
import Button from "./../elements/Button";
import gifLoader from "../../img/5.gif";
import swal from "sweetalert";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/button.css";
 import emailjs from "emailjs-com";

import PDF from "../../data/CRMBookV1.pdf";

const FreeBook = () => {
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [isLoading, setLoading] = useState(false);

  const getData = () => {
    const formData = {
      email,
    };
    return formData;
  };

  function validation(e) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    e.preventDefault();
     const data = getData();
    if (email === "") {
      swal({
        title: "Error, make sure the box is not empty!",
        text: "Your request hasn't been logged.",
        icon: "error",
        button: "close",
      });
      return false;
    }
    if (!email.includes("@")) {
      swal({
        title: "Error, email not detected!",
        text: "Your request hasn't been logged.",
        icon: "error",
        button: "close",
      });
      return false;
    } else {
      emailjs
        .send(
          "service_rtzy0pj",
          "template_hwehdv7",
          data,
          "user_PXR23NFHesbflxVcO00KJ"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("it works");
            setEmail("");
            swal({
              title: "Form submitted successfully!",
              text: "Your request has been logged.",
              icon: "success",
              button: "close",
            });
          },
          (error) => {
            console.log(error.text);
            setEmail("");
          }
        );
        window.open(PDF);
    }
  }
  return (
    <section id="free-book">
      <div className="">
        <h2 className="section-title" style={{ marginTop: "100px" }}>
          Download Free Book
        </h2>
        <p style={{ textAlign: "center" }}>
          Enter you business email to receive our free book.
        </p>
        <Form onSubmit={validation} className="Subscribe">
          <Form.Row controlId="formBasicEmail">
            <Col>
              <Form.Control
                style={{ width: "350px", height: "50px" }}
                type="email"
                required
                placeholder="Business Email"
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />{" "}
            </Col>
            <Col>
              {isLoading ? (
                ""
              ) : (
                  <Button
                    variant="primary"
                    type="button"
                    className="btn1 btn-background-slide"
                    text="Download Book"
                    click={validation}
                  ></Button>
              )}
              {isLoading ? (
                <div>
                  <img src={gifLoader} alt="" />
                </div>
              ) : (
                ""
              )}
            </Col>
          </Form.Row>
        </Form>
      </div>
    </section>
  );
};

export default FreeBook;
