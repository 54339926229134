import "./css/index.css";

import Header from "./components/layout/Header";
// import About from "./components/layout/About";
import Welcome from "./components/layout/Welcome";
import Training from "./components/layout/Trainings";
import Partners from "./components/layout/Partners";
import Contact from "./components/layout/Contact";
import Menu from "./components/elements/Menu";
import CookiesToolbar from "./components/layout/CookiesToolbar";
import CookiesOptions from "./components/layout/CookiesOptions";
import Footer from "./components/layout/Footer";
import Vision from "./components/layout/Vision";
import Feedback from "./components/elements/Feedback";
import FreeCall from "./components/elements/FreeCall";
import Login from "./components/elements/Login";
import Register from "./components/elements/Register";
import PrivacyPolicy from "./components/elements/PrivacyPolicy";
import Unsubscribe from "./components/elements/Unsubscribe";
import Licensing from "./components/elements/Licensing";
import LearnMore from "./components/elements/LearnMore";

import PopUp from "./components/elements/PopUp";

import Database from "./data/Database";

import Cookies from "universal-cookie";
import React, { Component } from "react";
import Axios from "axios";
import Products from "./components/layout/Products";
import Products2 from "./components/layout/Products2";
import Accordion from "./components/elements/Accordion";
import Introduction from "./components/layout/Introduction";

import Subscribe from "./components/elements/Subscribe";
import FreeBook from "./components/elements/FreeBook";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerVisible: false,
      burgerMenu: false,
      cookies: new Cookies(),
      PORT: "https://topone-uk-prod-toponecrm-web-app.azurewebsites.net",
      cookiesOptions: false,
      cookiesBar: true,
      about: false,
      trainings: false,
      products: false,
      products2: false,
      vision: false,
      contact: false,
      feedback: false,
      freeCall: false,
      licensing: false,
      login: false,
      register: false,
      popUp: false,
      popUpMsg: "",
      popUpConfirm: "",
      privacyPolicy: false,
      unsubscribe: false,
      learnMore: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleBurgerMenu = this.toggleBurgerMenu.bind(this);
    this.openCookiesOptions = this.openCookiesOptions.bind(this);
    this.agreeAll = this.agreeAll.bind(this);
    this.savePreferences = this.savePreferences.bind(this);
    this.closeFeedback = this.closeFeedback.bind(this);
    this.openFeedback = this.openFeedback.bind(this);
    this.closeFreeCall = this.closeFreeCall.bind(this);
    this.openFreeCall = this.openFreeCall.bind(this);
    this.closeLicensing = this.closeLicensing.bind(this);
    this.openLicensing = this.openLicensing.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
    this.openLogin = this.openLogin.bind(this);
    this.closeRegister = this.closeRegister.bind(this);
    this.openRegister = this.openRegister.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.openPopUp = this.openPopUp.bind(this);
    this.openPrivacy = this.openPrivacy.bind(this);
    this.closePrivacy = this.closePrivacy.bind(this);
    this.openUnSub = this.openUnSub.bind(this);
    this.closeUnSub = this.closeUnSub.bind(this);
    this.registerNewUser = this.registerNewUser.bind(this);
    this.logIn = this.logIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
    this.openLearnMore = this.openLearnMore.bind(this);
    this.closeLearnMore = this.closeLearnMore.bind(this);
  }

  registerNewUser(user) {
    // Axios.post(`${this.state.PORT}/users/register`, { ...user })
    //   .then((response) => {
    //     this.openPopUp("user register");
    //     this.closeRegister();
    //   })
    //   .catch((err) => console.log(err));

    Axios.post(`${this.state.PORT}/users/insertuserCRM`, { ...user })
      .then((response) => {
        this.openPopUp("Submitted");
        this.closeRegister();
        console.log(response);
      })
      .catch((err) => console.log(err));
  }
  logIn(data) {
    this.state.cookies.set("topOneLogIn", true, { path: "/" });
    this.state.cookies.set("detail", data, { path: "/" });
    console.log(this.state.cookies.getAll({ path: "/" }));
  }
  signOut() {
    this.state.cookies.set("topOneLogIn", false, { path: "/" });
    this.state.cookies.set("detail", {}, { path: "/" });
    console.log(this.state.cookies.getAll({ path: "/" }));
    this.openPopUp("You are signed out");
  }
  sendFeedback(feedback) {
    let userID;
    if (this.state.cookies.get("topOneLogIn", { path: "/" }) === "true") {
      userID = this.state.cookies.get("detail", { path: "/" }).userID;
    } else {
      userID = "anonymous";
    }
    Axios.post(`${this.state.PORT}/users/feedback`, { feedback, userID })
      .then((response) => {
        this.openPopUp("Feedback was successfully submitted", "ok");
        console.log(response);
      })
      .catch((err) => console.log(err));
  }

  handleScroll() {
    if (window.scrollY === 0) {
      this.setState({ headerVisible: false });
      return;
    } else {
      this.setState({ headerVisible: true });
    }

    // let about = document.getElementById("about");
    let trainings = document.getElementById("trainings");
    let products = document.getElementById("services");
    let products2 = document.getElementById("products");
    let vision = document.getElementById("project");
    let contact = document.getElementById("contact");
    // let showAbout = this.isInViewport(about) ? true : false;
    let showTrainings = this.isInViewport(trainings) ? true : false;
    let showProducts = this.isInViewport(products) ? true : false;
    let showProducts2 = this.isInViewport(products2) ? true : false;
    let showVision = this.isInViewport(vision) ? true : false;
    let showContacts = this.isInViewport(contact) ? true : false;
    this.setState({
      // about: showAbout,
      trainings: showTrainings,
      products: showProducts,
      products2: showProducts2,
      vision: showVision,
      contact: showContacts,
    });
  }

  isInViewport = function (elem) {
    let bounding = elem.getBoundingClientRect();
    return bounding.top < 800;
  };

  toggleBurgerMenu() {
    this.setState({ burgerMenu: !this.state.burgerMenu });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => this.openFeedback(), 15000);

    setTimeout(() => this.openFreeCall(), 10000);

    setTimeout(() => this.openLicensing(), 20000);
  }

  openCookiesOptions() {
    this.setState({ cookiesOptions: true, cookiesBar: false });
  }

  savePreferences(pref) {
    pref.forEach((element) => {
      this.state.cookies.set(element.name, element.allow, { path: "" });
    });
    this.setState({ cookiesOptions: false });
    console.log(this.state.cookies.getAll({ path: "/" }));
  }

  agreeAll() {
    Database.preferences.forEach((element) => {
      this.state.cookies.set(element.name, true, { path: "/" });
    });
    this.setState({ cookiesBar: false });
    console.log(this.state.cookies.getAll({ path: "/" }));
  }

  closeFeedback() {
    this.setState({ feedback: false });
  }
  openFeedback() {
    this.setState({ feedback: true });
  }

  closeFreeCall() {
    this.setState({ freeCall: false });
  }
  openFreeCall() {
    this.setState({ freeCall: true });
  }

  closeLicensing() {
    this.setState({ licensing: false });
  }
  openLicensing() {
    this.setState({ licensing: true });
  }

  closeLogin() {
    this.setState({ login: false });
  }
  openLogin() {
    this.setState({ login: true });
  }

  closeRegister() {
    this.setState({ register: false });
  }
  openRegister() {
    this.setState({ register: true });
  }

  closePopUp() {
    this.setState({ popUp: false, popUpMsg: "", popUpConfirm: "" });
  }
  openPopUp(msg, confirm = "close") {
    this.setState({ popUp: true, popUpMsg: msg, popUpConfirm: confirm });
  }

  openPrivacy() {
    this.setState({ privacyPolicy: true });
  }
  closePrivacy() {
    this.setState({ privacyPolicy: false });
  }

  openUnSub() {
    this.setState({ unsubscribe: true });
  }
  closeUnSub() {
    this.setState({ unsubscribe: false });
  }

  openLearnMore() {
    this.setState({ learnMore: true });
  }
  closeLearnMore() {
    this.setState({ learnMore: false });
  }

  render() {
    const {
      popUpConfirm,
      cookies,
      unsubscribe,
      privacyPolicy,
      popUp,
      popUpMsg,
      register,
      login,
      freeCall,
      licensing,
      feedback,
      headerVisible,
      cookiesBar,
      cookiesOptions,
      // about,
      trainings,
      products,
      products2,
      PORT,
      vision,
      contact,
      learnMore,
    } = this.state;

    return (
      <div id="main" className={`${headerVisible ? "scrolled" : ""}`}>
        <Welcome />

        {unsubscribe ? <Unsubscribe closeUnSub={this.closeUnSub} /> : ""}

        {register ? (
          <Register
            registerNewUser={this.registerNewUser}
            openPopUp={this.openPopUp}
            closeRegister={this.closeRegister}
          />
        ) : (
          ""
        )}

        {privacyPolicy ? (
          <PrivacyPolicy closePrivacy={this.closePrivacy} />
        ) : (
          ""
        )}

        {learnMore ? <LearnMore closeLearnMore={this.closeLearnMore} /> : ""}

        <Header
          signOut={this.signOut}
          cookies={cookies}
          openLogin={this.openLogin}
          toggleBurgerMenu={this.toggleBurgerMenu}
        />

        {login ? (
          <Login
            logIn={this.logIn}
            PORT={this.state.PORT}
            openPopUp={this.openPopUp}
            openRegister={this.openRegister}
            close={this.closeLogin}
          />
        ) : (
          ""
        )}

        <div
          id="burger-menu"
          className={this.state.burgerMenu ? "open" : "close"}
        >
          <ul>
            <Menu />
          </ul>
        </div>

        <Introduction />

        <Vision show={vision} />

        {/* <About show={about} preferences={this.openLearnMore} /> */}

        <Products2 show={products2} />
        <Products show={products} />

        <Training show={trainings} />

        <Accordion />

        <Subscribe />

        <FreeBook />

        <Partners />

        <Contact
          PORT={PORT}
          closePopUp={this.closePopUp}
          openPopUp={this.openPopUp}
          preferences={this.openPrivacy}
          show={contact}
        />

        {cookiesBar ? (
          <CookiesToolbar
            agree={this.agreeAll}
            preferences={this.openPrivacy}
          />
        ) : (
          ""
        )}

        {cookiesOptions ? (
          <CookiesOptions savePreferences={this.savePreferences} />
        ) : (
          ""
        )}

        <Footer openUnSub={this.openUnSub} />

        <Feedback
          sendFeedback={this.sendFeedback}
          visible={feedback ? true : false}
          closeFeedback={this.closeFeedback}
        />

        <FreeCall
          visible={freeCall ? true : false}
          closeFreeCall={this.closeFreeCall}
        />

        <Licensing
          visible={licensing ? true : false}
          closeLicensing={this.closeLicensing}
        />

        {popUp ? (
          <PopUp
            confirm={popUpConfirm}
            msg={popUpMsg}
            close={this.closePopUp}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
